

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  /* border: 3px solid green;  */
  
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  /* border: 3px solid red;     */
}


.services-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
   
  /* border: 3px solid green;  */
}


.services-column {
  -webkit-transition: all 1s ease;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  width: 25%; 
  min-width: 170px;
  max-width: 100%;
  border-radius: 10px;
  justify-content: space-between;
  flex-basis: 25%;

  /* border: 3px solid red;      */
}
.services-column:hover {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  width: 25%; 
  min-width: 170px;
  max-width: 100%;
  background-color: #070720;
  justify-content: space-between;
  flex-basis: 25%;

  /* border: 3px solid red;      */
}
.float-left {
  float:left; 
  margin: 10px;
}

.float-right {
  float:right; 
  margin: 10px;
}



body {
    scroll-behavior: smooth;
    background: #000000;
    overflow-x: hidden;
    color: #87929b;
    margin: 0;
    font-family: Poppins;
    /*display: flex;
    flex-direction: column;
    scroll-behavior: smooth;*/
}

h1,
h2,
h3 {
  color: #ededed;
  font-size:xx-large;
  font-weight: 600;
  text-align: center;
}

h3{
  font-size:20pt;
}

h1{
  font-size:100pt;
}

a {
  color: #dee3e9;
  font-family: Poppins;
  text-decoration: none;
}

p {
  color: #ced4da;
  font-size:14pt;
  font-weight: 300;
  text-align: center;
  font-family: Poppins;
}


p#telephone-content{
  color: #ced4da;
  font-size:11pt;
  font-weight: 300;
  text-align: center;
  font-family: Poppins;
}

.container-text p
{
  text-align:left;
}

#root
{
  max-width: 1280px;
  margin: 0 auto;
}


#full-panel
{    
  height:78vh;
}

#short-panel-services
{    
  height:60vh;
  min-height: 1100px;
  justify-content: center;
}
#short-panel-software
{    
  height:60vh;
  min-height: 800px;
  justify-content: center;
}
#short-panel-automation
{    
  height:60vh;
  min-height: 900px;
  justify-content: center;
}

#holding 
{     
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      /*display: flex;*/
      padding: 2pc;
      vertical-align: middle;
      /* border: 3px solid green;     */
}

#services-content 
{ 
   padding: 2pc;
   /* border: 3px solid green;    */
}

#strapline
{
  padding: 2pc;
  /*  border: 3px solid green;    */
}

#contact-content 
{ 
   padding: 2pc;
   /* border: 3px solid green;    */
}

#social-content 
{
  width: 32px;
  align-self: center;

}

#notfound-container a {
  text-align: center; 
}

#copyright-content{
  padding: 2pc;
}

#copyright-content p{
  color: #ced4da73;
  font-weight: 50;
  font-size: x-small;
  text-align: center;
  font-family: Poppins;
}

#section-content{
  height: 500px;  
}

#nav-content{
  padding: 5px;
  background-color: #000000;
  opacity: 0.2,
}

.nav-class {
  z-index:1; /*Add this*/
  position: fixed;
  top: 0px;
  width: 100%;

  background-color: #000000;
  
  height: 0rem;
  opacity: 0.9;
}


header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10%;
}

.logo{
    cursor: pointer;
}

#nav-row
{
  width:auto;
}

#nav-links
{
    list-style: none;
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 0 10px;
}


#nav-links a{
    display: flex;    
    font-weight: 500;
    font-size: 16px;
    color: #edf0f1;
    text-decoration: none;
    transition: all 0.3s ease 0s;
    align-content: center;
    align-items: center;
    text-align: center;
}

#nav-links a:hover{
    color: #38c6f1bd;

} 


#main-content{
  padding: 10px 2%;
  
}





.contact-form-container, .contact-form {
  display: flex;
  flex-direction:column;
  border-color: greenyellow;
}

.contact-form-container {
  padding: 5rem;
  border: 1px solid white;
  border-radius: 10px;
}

button{
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

input,textarea {
  margin: 0.5rem 0;
  padding: 1rem; 
  border: none;
  border-radius: 10px;
}
